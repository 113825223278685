import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "..";
import toast from "react-hot-toast";
import messages from "../../assets/Local/Local";
import { Navigate } from "react-router-dom";

const initialState = {
  allProjects: [],
  allProjectsLookups: [],
};

export const getJobDescriptionAlls = createAsyncThunk(
  "projects/allProjects",
  async () => {
    return await axiosInstance.get("Projects/GetAll");
  }
);

export const addProject = createAsyncThunk(
  "projects/addProject",
  async (data, { dispatch }) => {
    return await axiosInstance
      .post("Projects/AddProject", data)
      .then((response) => {
        // dispatch(getSingleJobDescriptionData(response.data.insertedID));
        toast.success(messages[localStorage.getItem("lang")].shared.done);
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        
        toast.error(error.response.data.error.message?error.response.data.error.message:'Something Wrong');
     
        return error;
      });
  }
);
const ProjectSlice = createSlice({
  name: "projects",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getJobDescriptionAlls.fulfilled, (state, action) => {
      state.loading = false;
      state.allProjects = action.payload.data.data;
      state.allProjectsLookups = action.payload.data.data.map((item) => ({
        id: item.id,
        name_en: item.projectName,
        name_ar: item.projectName,
      }));
    });
    builder.addCase(addProject.fulfilled, (state, action) => {});
  },
});

export const ProjectReducer = ProjectSlice.reducer;
